<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <shift-header
          :title="title"
          :path="path"
          :text="text"
          :text1="text1"
          :text2="text2"
          :text3="text3"
          :text4="text4"
          @add-shift="openAddShiftModel"
          @add-schedule="openAddScheduleModel"
          @add-dept-schedule="openAddDeptScheduleModel"
        />
        <!-- /Page Header -->
        <!-- Content Starts -->
        <!-- Search Filter -->
        <shift-scheduling-filter
          @search-schedule="searchSchedule"
          :sDate="sDate"
          :eDate="eDate"
        />
        <!-- Search Filter -->
        <div class="row">
          <div class="col-md-12">
            <draggable
              v-model="shifts"
              group="shift"
              class="row"
              :disabled="!hasCreatePermission"
            >
              <div
                class="col-md-4 col-lg-3 col-sm-4 p-1"
                v-for="shift in shifts"
                :key="shift.shift_id"
              >
                <div class="card mb-0">
                  <div class="card-header p-2">{{ shift?.shift_name }}</div>
                  <div class="card-body p-2">
                    <span class="username-info m-b-10"
                      >{{ convertUTCToLocal(shift?.start_time) }} -
                      {{ convertUTCToLocal(shift?.end_time) }} <br />
                      ({{
                        shift?.start_time && shift?.end_time
                          ? fetchTotalHours(shift?.start_time, shift?.end_time)
                          : ""
                      }})</span
                    >
                  </div>
                </div>
              </div>

              <div class="showentries mb-3">
                <label
                  >Show
                  <select
                    v-model="pagination.pageSize"
                    @change="fetchScheduleData"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries</label
                >
              </div>
              <div class="table-responsive">
                <a-table
                  class="stripped table-hover"
                  :columns="columns"
                  :data-source="scheduleData"
                  :pagination="pagination"
                  @change="handleTableChange"
                >
                  <template #bodyCell="{ column, record }">
                    <draggable
                      :list="record[column.key]"
                      group="shift"
                      @change="
                        handleDragChange(
                          record,
                          [record.id, column.key],
                          record[column.key]
                        )
                      "
                      :disabled="!hasCreatePermission"
                    >
                      <template v-if="column.key === 'Name'">
                        <h2 class="table-avatar">
                          <router-link
                            :to="'/profile/' + record?.id"
                            class="avatar"
                          >
                            <img
                              v-if="record?.avatar && record?.avatar != null"
                              :src="getEmployeeAvatar(record?.avatar)"
                              alt="User Image"
                              @error="handleImageError(record)"
                            />
                            <img
                              v-else
                              :src="
                                require(`@/assets/img/profiles/avatar-02.jpg`)
                              "
                              alt="User Image"
                            />
                          </router-link>
                          <router-link :to="'/profile/' + record?.id"
                            >{{ record.first_name }}
                            {{ record.last_name }}</router-link
                          >
                        </h2>
                      </template>
                      <template v-if="column.key != 'Name'">
                        <div
                          v-if="
                            record[column.key] && record[column.key]?.length
                          "
                        >
                          <div
                            class="user-add-shedule-list"
                            v-for="date_data in record[column.key]"
                            :key="'date_date_' + date_data"
                          >
                            <!-- <div class="close-icon">&#10006;</div> -->
                            <h2>
                              <a
                                href="javascript:;"
                                data-bs-toggle="modal"
                                data-bs-target="#add_schedule"
                                style="border: 2px dashed"
                                :style="{
                                  borderColor: assignShiftColor(
                                    date_data.shift_id
                                  ),
                                }"
                                @click="onEditSchedule(date_data)"
                                :disabled="hasEditPermission"
                              >
                                <span class="username-info m-b-10"
                                  >{{
                                    convertUTCToLocal(
                                      date_data?.shift?.start_time
                                    )
                                  }}
                                  -
                                  {{
                                    convertUTCToLocal(
                                      date_data?.shift?.end_time
                                    )
                                  }}
                                  {{
                                    date_data?.shift?.start_time &&
                                    date_data?.shift?.end_time
                                      ? fetchTotalHours(
                                          date_data?.shift?.start_time,
                                          date_data?.shift?.end_time
                                        )
                                      : ""
                                  }}</span
                                >
                                <span class="userrole-info">
                                  {{
                                    record?.company_name != null
                                      ? record?.company_name
                                      : ""
                                  }}</span
                                >
                              </a>
                            </h2>
                          </div>
                        </div>
                        <div class="user-add-shedule-list" v-else>
                          <a
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#add_schedule"
                            :disabled="hasCreatePermission"
                            @click="
                              onAddSchedule(
                                record.id,
                                column.key,
                                record.emp_department_id
                              )
                            "
                          >
                            <span><i class="fa-solid fa-plus"></i></span>
                          </a>
                        </div>
                      </template>
                    </draggable>
                  </template>
                </a-table>
              </div>
            </draggable>
          </div>
        </div>
        <!-- /Content End -->
      </div>
      <!-- /Page Content -->
      <shift-list-model
        @delete-shift="deleteShift"
        :deleteId="deleteID"
        :editShift="editShift"
        :closeModal="closeModal"
      ></shift-list-model>
      <edit-schedule-model
        :addEmployeeId="addEmployeeId"
        :editSchedule="editSchedule"
        @add-schedule="openAddScheduleModel"
        @reload-data="reloadData"
      ></edit-schedule-model>
      <dept-schedule-model
        @add-dept-schedule="openAddDeptScheduleModel"
        @reload-data="reloadData"
      ></dept-schedule-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import moment from "moment";
import { notification } from "ant-design-vue";
import { VueDraggableNext } from "vue-draggable-next";

var pagination = { total: 0, current: 1, pageSize: 10 };

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());

const columns = [
  {
    title: "Scheduled Shift",
    dataIndex: "Name",
    key: "Name",
    sorter: false,
    fixed: "left",
  },
];

export default {
  emits: ["reload-data"],
  data() {
    return {
      perpage: 10,
      pagination: pagination,
      scheduleData: [],
      params: null,
      title: "Daily Scheduling",
      path: "Dashboard",
      text: "Employees",
      text1: "Shift Scheduling",
      text2: "Shifts",
      text3: "Assign Shifts",
      text4: "Assign Department Shifts",
      isFocused: false,
      startdate: currentDate,
      startdateone: currentDateOne,
      columns_d: columns,
      columns: null,
      deleteID: null,
      editShift: null,
      editSchedule: null,
      closeModal: false,
      dates: [],
      shifts: [],
      addEmployeeId: null,
      addDeptId: null,
      searchData: null,
      sDate: null,
      eDate: null,
      hasCreatePermission: null,
      hasEditPermission: null,
      hasDeletePermission: null,
      shiftColors: [
        "#FF0000", // red
        "#0000FF", // blue
        "#FF00FF", // magneta
        "#808000", // yellow green
        "#8B4513", // brown
        "#F82F72", // pink
        "#A245F7", // purple
        "#00BFFF", // deep sky blue
        "#D0CB05", // yellow type
        "#4BA8ED", // sky
        "#1EB53A", // green
        "#FF8000", // orange
        "#404040", // grey
        "#7B68EE", // medium slate blue
        "#008B8B", // dark cyan
      ],
    };
  },
  components: {
    draggable: VueDraggableNext,
  },
  methods: {
    assignShiftColor(index) {
      return this.shiftColors[index % this.shiftColors.length];
    },
    handleDragChange(record, key, date_data) {
      if (record[key[1]].length) {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const data = {
          record: record,
          key: key,
          date_data: date_data,
        };

        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false,
        });

        axios
          .post("/schedule/dragged-schedule-data", data)
          .then((response) => {
            this.reloadData();
            loader.hide();

            notification.open({
              message: response.data.message || "Schedule updated Successfully",
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
          })
          .catch((error) => {
            loader.hide();
            this.reloadData();

            var response = error.response;

            if (
              error.response.status == 401 &&
              response.data.message == "Unauthenticated."
            ) {
              localStorage.clear();

              notification.open({
                message: "Please Login",
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });

              this.$router.push({ name: "login" }).catch((error) => {});
            } else {
              this.errorMessage = error.message;
              notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            }
          });
      }
    },
    reloadData() {
      this.fetchScheduleData(this.searchData);
      this.getAllShiftList();
    },
    onAddSchedule(id, date, department_id) {
      this.addEmployeeId = null;
      this.$nextTick(() => {
        this.addEmployeeId = {
          id: id,
          date: date,
          department_id: department_id,
        };
      });
    },
    handleTableChange(pagesize) {
      const mergeObj = { ...pagesize, ...this.searchData };

      this.fetchScheduleData(mergeObj);
    },
    handleImageError(record) {
      record.avatar = null;
      record.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    getEmployeeAvatar(avatar) {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`;
    },
    addFocusClass() {
      this.isFocused = true;
    },
    removeFocusClass() {
      this.isFocused = false;
    },
    onDeleteShift(id) {
      this.deleteID = id;
    },
    onEditSchedule(record) {
      this.editSchedule = null;
      this.$nextTick(() => {
        this.editSchedule = record;
      });
    },
    openAddShiftModel() {
      this.editShift = null;
    },
    openAddScheduleModel() {
      this.addEmployeeId = null;
      this.editSchedule = null;
    },
    openAddDeptScheduleModel() {
      this.addDeptId = null;
      this.editSchedule = null;
    },
    searchSchedule(params) {
      this.searchData = params;
      this.fetchScheduleData(this.searchData);
    },
    setColumnData(startDate, endDate) {
      const start_date = moment(startDate);
      const end_date = moment(endDate);
      const dateColumn = [];

      this.sDate = start_date;
      this.eDate = end_date;

      while (start_date.isSameOrBefore(end_date)) {
        dateColumn.push([
          start_date.format("YYYY-MM-DD"),
          start_date.format("ddd D"),
        ]);
        this.dates.push(start_date.format("YYYY-MM-DD"));
        start_date.add(1, "day");
      }

      var datesColumn = [];

      dateColumn.forEach((element) => {
        datesColumn.push({
          title: element[1],
          dataIndex: element[1],
          key: element[0],
        });
      });

      this.columns = [...this.columns_d, ...datesColumn];
    },
    async fetchScheduleData(pagesize = null) {
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const start_date = moment().format("YYYY-MM-DD");
      const end_date = moment().add(5, "days").format("YYYY-MM-DD");

      var params = {
        params: {
          per_page: this.pagination.pageSize,
          start_date: start_date,
          end_date: end_date,
        },
      };

      if (
        pagesize &&
        pagesize != null &&
        pagesize?.pageSize != null &&
        pagesize?.current != null
      ) {
        params.params.per_page = pagesize.pageSize;
        params.params.page = pagesize.current;
      }

      if (pagesize && pagesize?.employee_id != null) {
        params.params.employee_id = pagesize.employee_id;
      }

      if (pagesize && pagesize?.department_id != null) {
        params.params.department_id = pagesize.department_id;
      }

      if (pagesize && pagesize?.department_id != null) {
        params.params.department_id = pagesize.department_id;
      }

      if (pagesize && pagesize?.start_date != null) {
        params.params.start_date = pagesize.start_date;
      }

      if (pagesize && pagesize?.end_date != null) {
        params.params.end_date = pagesize.end_date;
      }

      this.setColumnData(params?.params?.start_date, params?.params?.end_date);

      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false,
      });
      await axios
        .get("schedule/list", params)
        .then((response) => {
          if (response.status == 200) {
            this.pagination.total = response?.data?.data?.total;
            this.pagination.current = response?.data?.data?.current_page;
            this.pagination.pageSize = response?.data?.data?.per_page;

            if (response?.data?.data?.data) {
              this.scheduleData = response?.data?.data?.data;
            } else {
              this.scheduleData = [];
            }
          }

          loader.hide();
        })
        .catch((error) => {
          loader.hide();
          console.log("wages error");
          console.log(error);
          var response = error.response;

          this.scheduleData = [];

          if (
            error.response.status == 401 &&
            response.data.message == "Unauthenticated."
          ) {
            localStorage.clear();

            notification.open({
              message: "Please Login",
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });

            this.$router.push({ name: "login" }).catch((error) => {});
          } else {
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        });
    },
    async deleteShift(id) {
      if (id != null) {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        await axios
          .delete("/shifts/" + id, [])
          .then((response) => {
            notification.open({
              message: response?.data?.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
            this.closeModal = true;
            this.deleteID = null;
          })
          .catch((error) => {
            this.closeModal = true;
            this.deleteID = null;
            if (error.response) {
              var response = error.response;

              notification.open({
                message: response?.data?.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            } else {
              notification.open({
                message: "Server Error",
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            }
          });
      }
    },
    getScheduleDataDateUserWise(date, record) {
      record.date_data = [];
      if (date && record && record?.schedule_data?.length) {
        record.schedule_data.forEach((schedule) => {
          if (schedule.date == date) {
            record.date_data.push(schedule);
          }
        });
      }
      return record.date_data;
    },
    fetchTotalHours(startTime, endTime) {
      if (startTime && endTime) {
        const startDate = new Date(`2000-01-01 ${startTime}`);
        const endDate = new Date(`2000-01-01 ${endTime}`);
        // Calculate the time difference in milliseconds
        const timeDifference = endDate - startDate;

        // Calculate hours and minutes
        const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
        const totalMinutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        // Format the result
        const formattedResult = `${totalHours} hrs ${totalMinutes} min`;

        return formattedResult;
      }
    },
    convertUTCToLocal(time) {
      if (time) {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        // Determine AM or PM
        const period = hours >= 12 ? "PM" : "AM";

        let m = minutes;

        // Convert hours to 12-hour format
        let hours12 = hours % 12;
        hours12 = hours12 === 0 ? 12 : hours12; // 0 should be treated as 12 in 12-hour format
        if (m < 10) {
          m = "0" + m;
        }

        if (hours12 < 10) {
          hours12 = "0" + hours12;
        }

        // Construct the formatted time string
        const formattedTime = `${hours12}:${m}`;

        // Return the formatted time with period
        return `${formattedTime} ${period}`;
      }
      return null;
    },
    async getAllShiftList() {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get("shifts/all");

        this.shifts = response?.data?.data || null;
        // console.log(this.shifts);
      } catch (error) {
        return error.response.data;
      }
    },
  },
  created() {
    this.$store
      .dispatch("userPermissions", { module: "schedule", action: "create" })
      .then((response) => {
        this.hasCreatePermission = null;
        this.$nextTick(() => {
          this.hasCreatePermission = response;
        });
      });

    this.$store
      .dispatch("userPermissions", { module: "schedule", action: "write" })
      .then((response) => {
        this.hasEditPermission = null;
        this.$nextTick(() => {
          this.hasEditPermission = response;
        });
      });

    this.$store
      .dispatch("userPermissions", { module: "schedule", action: "delete" })
      .then((response) => {
        this.hasDeletePermission = null;
        this.$nextTick(() => {
          this.hasDeletePermission = response;
        });
      });

    this.sDate = null;
    this.eDate = null;

    const start_date = moment().format("YYYY-MM-DD");
    const end_date = moment().add(5, "days").format("YYYY-MM-DD");

    this.$nextTick(() => {
      this.sDate = start_date;
      this.eDate = end_date;
    });

    this.fetchScheduleData();
    this.getAllShiftList();
  },
};
</script>

<style>
th.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  z-index: 1;
}
td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  z-index: 1;
}
</style>